import { Button, Grid, Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CurrencyFormat from 'src/components/currency/Currency';
import Store from 'src/stores/RootStore';
import UserStore from 'src/stores/UserStore';
import { InvoiceType, TInvoiceLine } from 'src/stores/types/Invoice';
import { ProductCategoryTypes } from 'src/utils/constants';

interface InvoiceLinesProps extends WithStyles<typeof styles> {
    store?: typeof Store.Type;
    user?: typeof UserStore.Type;
    lines: TInvoiceLine[];
    type: string;
    returnInvoiceLine: Function;
    allowReturnOnHover: boolean;
}

const InvoiceLines = inject(
    'store',
    'user',
)(
    observer(({ classes, lines, returnInvoiceLine, store, user, type, allowReturnOnHover }: InvoiceLinesProps) => {
        const selectLine = (line: TInvoiceLine) => {
            line.getParentBikes().then(() => {
                returnInvoiceLine(line.id);
                store!.returns.toggleReturnFormDialog();
            });
        };

        return (
            <>
                <Grid container className={classes.tableHeader}>
                    <Grid item xs={1}>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="invoices.no" />
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="invoices.product" />
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="invoices.quantity" />
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="invoices.unitPrice" />
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="invoices.discount" />
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="invoices.vat" />
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="invoices.total" />
                        </Typography>
                    </Grid>
                </Grid>

                {lines &&
                    lines.map((line: TInvoiceLine, index: number) =>
                        line.unitPrice ? (
                            <Grid
                                key={`${line.code}-${index}`}
                                container
                                className={`${classes.tableRow} ${!allowReturnOnHover ? classes.notAProduct : ''}`}
                            >
                                {allowReturnOnHover &&
                                    type === InvoiceType.INVOICE &&
                                    !!line.productType &&
                                    line.productType !== ProductCategoryTypes.BIKE &&
                                    line.productType !== ProductCategoryTypes.BATTERY && (
                                        <div className={classes.tableRowHover}>
                                            <Button
                                                onClick={() => selectLine(line)}
                                                color="primary"
                                                variant="contained"
                                            >
                                                <FormattedMessage id="returns.returnItem" />
                                            </Button>
                                        </div>
                                    )}
                                <Grid item xs={2}>
                                    <Typography className={classes.containerAligned} variant="subtitle1">
                                        {line.code}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography className={classes.containerAligned} variant="subtitle1">
                                        {line.description}
                                    </Typography>
                                </Grid>
                                <Grid className={classes.containerAligned} item xs={1}>
                                    <Typography className={classes.containerAligned} variant="subtitle1">
                                        {line.quantity}
                                    </Typography>
                                </Grid>
                                <Grid className={classes.containerAligned} item xs={2}>
                                    <Typography className={classes.containerAligned} variant="subtitle1">
                                        <CurrencyFormat value={line.unitPrice || 0} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography className={classes.containerAligned} variant="subtitle1">
                                        {`${line.discountPercentage || 0} %`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography className={classes.containerAligned} variant="subtitle1">
                                        {`${line.vatPercentage || 0} %`}
                                    </Typography>
                                </Grid>
                                <Grid className={classes.containerAligned} item xs={1}>
                                    <Typography className={classes.containerAligned} variant="subtitle1">
                                        <CurrencyFormat value={line.amountWithDiscount || 0} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : null,
                    )}
            </>
        );
    }),
);

function styles({ palette }: Theme) {
    return createStyles({
        '@keyframes fadeIn': {
            from: {
                opacity: 0,
            },
            to: {
                opacity: 1,
            },
        },
        containerAligned: {
            display: 'flex',
            alignItems: 'center',
        },
        tableHeader: {
            borderBottom: `1px solid ${palette.secondary.light}`,
            paddingBottom: '.5rem',
            marginBottom: '.5rem',
        },
        tableRow: {
            margin: '.5rem 0',
            padding: '.5rem 0',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            '&:hover > $tableRowHover': {
                display: 'flex',
            },
        },
        tableRowHover: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(255,255,255,.5)',
            top: 0,
            left: 0,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'none',
            animationDuration: '.3s',
            animationFillMode: 'both',
            animationName: '$fadeIn',
        },
        notAProduct: {
            opacity: 0.4,
        },
    });
}

export default withStyles(styles)(InvoiceLines);
